<script>
import appConfig from "@/app.config";

/**
 * Recover-password component
 */
export default {
  page: {
    title: "Reset Passsword",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  mounted() {
   // document.body.classList.add("authentication-bg");
  },
  data() {
    return {
      title: "Reset Password",
      errorMsg:"",
      showOTP:true,
      resetPw:false,
      emailID:"",
      password:"",
      confirmPassword:"",
      otp:"",
      loading:false,
      errors:[],
      notiText: "Enter your Email and instructions will be sent to you!",
    }
  },
  methods: {
    sendOtp(){
      if (!this.emailID) {
        this.errorMsg = "Please Enter Email ID";
      } else {
          this.loading = true;
          let formData = new FormData();
          formData.append('emailID', this.emailID);
          formData.append('type', this.$route.params.type);
      this.axios.post("/sendOTPForResetPw",  formData)
      .then((result)=>{
        this.status = result.data.status;
          if(this.status==200){
            this.loading = false;
            this.errorMsg = "";
              this.showOTP = false;
              this.resetPw = true;
          }else{
            this.$swal({
              text:  "Something Went wrong, try again",
              icon: "error",
            });
          }

      })
      .catch((error)=> {
                       this.loading = false;
        this.$swal({
          text:  error.response.data.message,
          icon: "error",
        });
                    });
      }  
    },
    submitForm(){

        if (!this.otp || !this.password || !this.confirmPassword) {
          this.errorMsg = "Required fields are missing";
          return;
        }
        if(this.password!=this.confirmPassword){
            this.errorMsg = "Password is not matching";
            return;
        }
     
          this.loading = true;
          let formData = new FormData();
          formData.append('emailID', this.emailID);
          formData.append('otp', this.otp);
          formData.append('password', this.password);
          formData.append('confirmPassword', this.confirmPassword);
          formData.append('type', this.$route.params.type);
        
      this.axios.post("/resetPassword",  formData)
      .then((result)=>{
           this.loading = false;
           this.showOTP = false;
            this.resetPw = false;
          this.notiText = result.data.message;
      })
      .catch((error)=> {
                       this.loading = false;
                       this.errorMsg = error.response.data.message;
                    });
      }  
    
  },
};
</script>

<template>
  <div>
  
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div>
              
              <div class="card">
                <div class="card-body p-4">
                  <div class="text-center mt-2">
                    <router-link to="/mainLoginPage" class="mb-3 d-block auth-logo">
                        <img
                          src="@/assets/images/logo.png"
                          alt
                          height="60"
                          class="logo" style="object-fit:cover;"
                        />
                      
                      </router-link>

                    <h5 class="text-primary">Reset Password</h5>
                    <p class="text-muted">Reset Password with CloudKitch.</p>
                  </div>
                  <div class="p-2 mt-4">
                    <div
                      class="alert alert-success text-center mb-4"
                      role="alert"
                    >
                      {{notiText}}
                    </div>
                    <form>
            
                      <div class="mb-3" v-if="showOTP">
                          <label for="useremail">Email*</label>
                          <input 
                            type="email"
                            class="form-control"
                            id="useremail"
                            placeholder="Enter email"
                            v-model="emailID"
                          />
                          <p style="color:red;">{{errorMsg}} </p>
                      </div>
                     
                      <div class="mt-3 text-end"  v-if="showOTP">
                          <b-spinner v-if="loading"
                              class="m-2 col-3"
                              variant="primary"
                              role="status"
                            ></b-spinner>
                            <button 
                              class="btn btn-primary w-sm waves-effect waves-light"
                              type="button"
                              @click="sendOtp"
                              :disabled="loading"
                            >
                              Send OTP
                            </button>
                      </div>
                  

                    <!-- Verify otp div -->
                      <div class="mb-3" v-if="resetPw">
                        <label>OTP</label>
                        <input 
                          type="number"
                          class="form-control"
                          v-model="otp"
                          placeholder="Enter OTP"
                         
                        />
                      </div>
                      <div class="mb-3" v-if="resetPw">
                        <label>New Password</label>
                        <input 
                          type="password"
                          class="form-control"
                          v-model="password"
                          placeholder="Enter new password"
                         
                        />
                      </div>
                      <div class="mb-3" v-if="resetPw">
                        <label>Confirm Password</label>
                        <input 
                          type="password"
                          class="form-control"
                          v-model="confirmPassword"
                          placeholder="Confirm password"
                         
                        />
                        <p style="color:red;">{{errorMsg}} </p>
                      </div>
                      
                      
                    

                      <div class="mt-3 text-end" v-if="resetPw">
                            <b-spinner v-if="loading"
                              class="m-2 col-3"
                              variant="primary"
                              role="status"
                            ></b-spinner>
                            <button 
                              class="btn btn-primary w-sm waves-effect waves-light"
                              type="button"
                              @click="submitForm"
                              :disabled="loading"
                            >
                              Reset
                            </button>
                      </div>
                      

                    </form>
                  </div>
                </div>
              </div>
              <div class="mt-5 text-center">
                <p>
                  © {{ new Date().getFullYear() }} CloudKitch Pvt Ltd. 
                  
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
  </div>
</template>
